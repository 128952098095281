import { Box, FormControlLabel, Tooltip, Typography, makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { MdOutlineSearchOff } from 'react-icons/md'
import { Scrollbar } from 'shared'
import Pagination from 'shared/TableCustom/TablePagination'

import { Loading } from '../../styles'
import {
  Accordion,
  ButtonApprovedFinanceOrder,
  ButtonRecusedFinanceOrder,
  CardContainerOrdersStyled,
  ContainerAccordions,
  ContainerLabels,
  ContainerLabelsAccordion,
  ContainerScroll,
  Search,
} from './styles'
import { ModalApprovedFinanceOrder } from 'components/pages/Invoice/ModalApprovedFinanceOrder'
import { ModalCanceledFinanceOrder } from 'components/pages/Invoice/ModalCanceledFinanceOrder'
import { IOrder } from 'interfaces/IOrder'
import { useFinanceOrder } from 'hooks/useFinanceOrder'
import { usePopup } from 'hooks/usePopup'
import { SwitchIOS } from 'components'
import { settingsService } from 'services/settingsService'

export const useStyles = makeStyles({
  root: {
    margin: '13rem auto',
    color: '#757575',
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
})

const CardContainerOrdersFinance = () => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [internetConnectionOk, setInternetConnectionOk] = useState(false)
  const [pendingOrderBlocksLoading, setPendingOrderBlocksLoading] = useState(true)
  const [isLoadingToggle, setIsLoadingToggle] = useState(false)
  const { data, params, isLoading, onChange, count, search, searchDebaunce, pollingInvoice } =
    useFinanceOrder()

  const [isOrderFinanceApproved, setIsOrderFinanceApproved] = useState({
    isOpen: false,
    order: null as any as IOrder,
  })

  const [isOrderFinanceCanceled, setIsOrderFinanceCanceled] = useState({
    isOpen: false,
    order: null as any as IOrder,
  })

  const checkInternetConnection = async () => {
    try {
      await fetch('https://www.google.com', { mode: 'no-cors' })
      setInternetConnectionOk(true)
      if (!pendingOrderBlocksLoading) {
        handleIgnorePendingOrders(true)
      }
    } catch (error) {
      setInternetConnectionOk(false)
      addPopup({
        type: 'error',
        title: 'Verificação de internet no CD executada.',
        description: `O CD está sem internet.`,
      })
    }
  }

  const checkIgnorePendingOrders = async () => {
    try {
      const dataSettings = await settingsService.getSettings()
      setPendingOrderBlocksLoading(dataSettings.pending_order_blocks_loading)
    } catch (error) {
      addPopup({
        type: 'error',
        title: 'Erro ao verificar configuração de trava da esteira',
      })
    }
  }

  const updateIgnorePendingOrders = async (PendingOrderBlocksLoading: boolean) => {
    try {
      await settingsService.updateSettings({ PendingOrderBlocksLoading })
      addPopup({
        type: 'success',
        title: 'Alteração realizada com sucesso.',
      })
    } catch (error) {
      addPopup({
        type: 'error',
        title: 'Erro ao alterar configuração de trava da esteira',
      })
    }
  }

  const handleIgnorePendingOrders = async (changeConfig: boolean) => {
    setIsLoadingToggle(true)
    await updateIgnorePendingOrders(changeConfig)
    await checkIgnorePendingOrders()
    setIsLoadingToggle(false)
  }

  const handleCloseOrderConfirm = () => {
    setIsOrderFinanceApproved({
      isOpen: false,
      order: null as any as IOrder,
    })
    setIsOrderFinanceCanceled({
      isOpen: false,
      order: null as any as IOrder,
    })
  }

  const handleOrderFinanceAproved = (e: any, order: IOrder) => {
    e.stopPropagation()
    setIsOrderFinanceApproved({
      isOpen: true,
      order,
    })
  }

  const handleOrderFinanceRecused = (e: any, order: IOrder) => {
    e.stopPropagation()
    setIsOrderFinanceCanceled({
      isOpen: true,
      order,
    })
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkInternetConnection()
    }, 60000)

    checkInternetConnection()
    checkIgnorePendingOrders()

    return () => clearInterval(intervalId)
  }, [])

  return (
    <CardContainerOrdersStyled>
      <Loading loading={isLoading} />
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Search
          placeholder="N° do pedido ou da rota"
          onChange={e => {
            searchDebaunce(e.target.value)
          }}
          defaultValue={search}
        />
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip
            title="Se desativar, pedidos ainda pendentes não irão travar o carregamento.
          Você só pode desativar caso o CD esteja sem internet e a quantidade de pedidos pendentes seja maior que 50.
          A trava é ativada automaticamente caso a internet volte."
          >
            <FormControlLabel
              control={
                <SwitchIOS
                  checked={pendingOrderBlocksLoading}
                  onChange={() => handleIgnorePendingOrders(!pendingOrderBlocksLoading)}
                />
              }
              label={
                isLoadingToggle
                  ? 'Opa, alterando...'
                  : pendingOrderBlocksLoading
                  ? 'Verificação de pedido na esteira: ATIVADO'
                  : 'Verificação de pedido na esteira: DESATIVADO'
              }
              style={{ justifyContent: 'flex-end', marginRight: '0', fontWeight: 'bold' }}
              disabled={
                isLoadingToggle || internetConnectionOk || (!internetConnectionOk && count < 50)
              }
            />
          </Tooltip>
        </Box>
      </Box>
      {!isLoading && data.length === 0 && (
        <Typography className={classes.root}>
          <MdOutlineSearchOff size={50} />
          Nenhum pedido com pagamento pendente encontrado
        </Typography>
      )}

      {data.length > 0 && (
        <>
          <ContainerScroll>
            <ContainerAccordions>
              <ContainerLabels>
                <span>Rota</span>
                <span>N° Pedido</span>
                <span>Cliente</span>
              </ContainerLabels>
              {data.map(order => (
                <Accordion key={order.order_id}>
                  <ContainerLabelsAccordion>
                    <span>{order.route || 'Sem rota'}</span>
                    <span>{order.order_id}</span>
                    <span>{order.client_data?.client_trade || order.client_data?.client_name}</span>
                    <Tooltip
                      title={
                        internetConnectionOk
                          ? 'Você só pode usar esse botão quando o CD estiver sem internet.'
                          : ''
                      }
                      placement="top"
                    >
                      <span>
                        <ButtonApprovedFinanceOrder
                          onClick={e => {
                            handleOrderFinanceAproved(e, order)
                          }}
                          disabled={internetConnectionOk}
                        >
                          Aprovar
                        </ButtonApprovedFinanceOrder>
                        <ButtonRecusedFinanceOrder
                          onClick={e => {
                            handleOrderFinanceRecused(e, order)
                          }}
                          disabled={internetConnectionOk}
                        >
                          Cancelar
                        </ButtonRecusedFinanceOrder>
                      </span>
                    </Tooltip>
                  </ContainerLabelsAccordion>
                </Accordion>
              ))}
            </ContainerAccordions>
          </ContainerScroll>
          <Scrollbar>
            <Pagination
              page={params.page}
              count={count}
              onChange={(_, newPage: number) => onChange({ page: newPage })}
              rowsPerPage={params.pageSize}
              handleRowsPerPageChange={newPageSize => onChange({ pageSize: newPageSize, page: 1 })}
            />
          </Scrollbar>
        </>
      )}

      <ModalApprovedFinanceOrder
        open={isOrderFinanceApproved.isOpen}
        pollingInvoice={pollingInvoice}
        handleClose={handleCloseOrderConfirm}
        order={isOrderFinanceApproved.order}
      />

      <ModalCanceledFinanceOrder
        open={isOrderFinanceCanceled.isOpen}
        pollingInvoice={pollingInvoice}
        handleClose={handleCloseOrderConfirm}
        order={isOrderFinanceCanceled.order}
      />
    </CardContainerOrdersStyled>
  )
}

export default CardContainerOrdersFinance
