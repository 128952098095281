import { Box, Container, LinearProgress } from '@material-ui/core'
import { ContainerPage, Summary, TitlePage } from 'components'
import {
  CardBreak,
  CardInfo,
  CardInfoToggle,
  CardRoute,
  ModalBreak,
  SeparatorList,
} from 'components/pages/Dashboard'
import { ModalRoute } from 'components/pages/Dashboard/ModalRoute'
import { useBreak } from 'hooks/useBreak'
import { useConferenceChuchu } from 'hooks/useConferenceChuchu'
import { useDashboard } from 'hooks/useDashboard'
import { IRouteDashboard } from 'interfaces/IDashboard'
import { useState, useEffect, useRef } from 'react'

import { useStyles } from './styles'

const Dashboard = () => {
  const classes = useStyles()
  const [modalBreak, setModalBreak] = useState(false)
  const { dashboardData, refreshDashboard, loading } = useDashboard()
  const { recoveryConference } = useConferenceChuchu()
  const { breaks } = useBreak()
  const [showLiquidTime, setShowLiquidTime] = useState(false)
  const [isBoxFixed, setIsBoxFixed] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null)
  const [openModalRoute, setOpenModalRoute] = useState({
    open: false,
    routeData: {} as IRouteDashboard,
  })
  const {
    data,
    completed_orders,
    total_orders,
    total_volume,
    total_ifood_orders,
    total_frexco_orders,
    processed_volume,
    lastUpdate,
    orders_in_progress,
    average_idle_time,
    tons_per_hour,
  } = dashboardData

  const pendentOrders = total_orders - orders_in_progress - completed_orders

  const toggleModalBreak = () => {
    setModalBreak(state => !state)
  }

  const handleClose = () => {
    setOpenModalRoute(state => ({ ...state, open: false }))
  }
  const onOpenModal = (routeData: IRouteDashboard) => {
    setOpenModalRoute(state => ({ ...state, open: true, routeData }))
  }

  const handleMouseEnter = () => {
    if (!isBoxFixed) setShowLiquidTime(true)
  }

  const handleMouseLeave = () => {
    if (!isBoxFixed) setShowLiquidTime(false)
  }

  const handleClick = () => {
    setIsBoxFixed(!isBoxFixed)
    setShowLiquidTime(!isBoxFixed)
  }

  const AverageTime = () => (
    <div ref={boxRef}>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
        {showLiquidTime || isBoxFixed ? (
          <CardInfo
            title="Tempo médio líquido / pedido"
            isLiquidTime={true}
            timeData={Number(dashboardData.avg_time_first_to_last_item.average_time_seconds)}
          />
        ) : (
          <CardInfo
            title="Tempo médio bruto / pedido"
            isLiquidTime={false}
            timeData={Number(dashboardData.average_duration)}
          />
        )}
      </Box>
    </div>
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setIsBoxFixed(false)
        setShowLiquidTime(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [boxRef])

  useEffect(() => {
    recoveryConference()
  }, [])

  return (
    <>
      <ContainerPage>
        <TitlePage>Dashboard</TitlePage>
        <Box className={classes.containerInfos}>
          <CardInfoToggle
            title="Previsão de término"
            totalOrder={dashboardData.total_orders - dashboardData.completed_orders}
            endTime={dashboardData.end_time}
          />

          <AverageTime />

          <CardBreak quantity={breaks.length} action={toggleModalBreak} />

          <CardRoute onOpenModal={onOpenModal} />
        </Box>
        <Container className={classes.content}>
          <Box className={classes.summaryContainer}>
            <Summary
              totalItens={dashboardData?.total_items}
              totalOrders={total_orders}
              totalFrexcoOrders={total_frexco_orders}
              totalIfoodOrders={total_ifood_orders}
              completed={completed_orders}
              separationOrders={orders_in_progress}
              processedVolume={processed_volume}
              totalVolume={total_volume}
              lastUpdate={lastUpdate}
              pendentOrders={pendentOrders}
              tonsPerHour={tons_per_hour}
            />
          </Box>

          <Box className={classes.groupsContainer}>
            <LinearProgress
              style={{ height: '10px', borderRadius: '4px', opacity: loading ? 1 : 0 }}
            />
            <SeparatorList
              separators={data}
              averageDuration={dashboardData.average_duration}
              pendentOrders={pendentOrders}
              refreshDashboard={refreshDashboard}
              averageIdleTime={average_idle_time}
            />
          </Box>
        </Container>
      </ContainerPage>

      <ModalBreak open={modalBreak} handleClose={toggleModalBreak} breaks={breaks} />
      <ModalRoute
        data={openModalRoute.routeData}
        handleClose={handleClose}
        open={openModalRoute.open}
      />
    </>
  )
}

export default Dashboard
